<template>
    <v-flex xs12 class="padContainer">
        <v-card @click.native="clicked()" style="width: 100%;margin-bottom: 1px;" :class="{'dark-mode':$store.state.NightMode.sideNav == 'dark-mode','light-mode':$store.state.NightMode.sideNav == 'light-mode'}">
            <v-container fluid style="padding: 10px;">

                <v-card-text style="padding: 2px;">
                    <v-layout wrap>
                        <v-flex xs12>
                            <div style="font-size: 1.2em;">{{liItem.currencyName}}</div>
                        </v-flex>
                        <v-flex xs6>
                            <div style="font-size: .9em;">{{liItem.currencyShort}}</div>
                        </v-flex>
                        <v-flex xs6>
                            <div style="font-size: .9em;">GCT: {{liItem.gct }}%</div>
                        </v-flex>
                    </v-layout>
                </v-card-text>

            </v-container>
        </v-card>
    </v-flex>
</template>

<script>
  import listItemData from './listItemData.vue'

  export default {
    components: {
      ListItemData: listItemData
    },
    props: ['liItem'],
    //data() {
    //  return {
    //    //lItem: {
    //    //  id: 0,
    //    //  listType: '',
    //    //  name: '',
    //    //  account: '',
    //    //  type: '',
    //    //  amount: 0,
    //    //  amountSpent: 0,
    //    //  refillAmount: 0,
    //    //  date: '',
    //    //  currency: '',

    //    //  show: false
    //    //}
    //  }
    //},
    methods: {
      redirectToEditPage: function (itemId) {
        this.$emit('Edit', itemId)
      },
      DeleteItem: function (lItem) {
        this.$emit('Delete', lItem)
      },
      itemClicked: function (item) {
        this.$emit('clickedItem', item)
      },
      clicked: function () {
        this.lItem.show = !this.lItem.show
        this.itemClicked(this.lItem)
      },
    }
  }

</script>

<style scoped>

    .flex {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .padContainer {
        padding-top: 4px !important;
        padding-bottom: 4px !important;
    }
</style>
